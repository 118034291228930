import Button from '@components/controls/button';

import mailCheckImage from '@assets/images/mail-check.svg';

const RegistrationInitSuccess = ({
  resent = false,
  resendEmailVerification,
}: {
  resent?: boolean;
  resendEmailVerification: () => void;
}) => {
  return (
    <>
      <h1 class="mb-2.5 font-redhat text-2xl font-bold sm:text-4xl">
        {resent
          ? 'Eine E-Mail zur Bestätigung Ihres Kontos wurde erneut versendet.'
          : 'Vielen Dank für Ihre Anmeldung!'}
      </h1>
      <div class="my-3 flex items-center justify-center">
        <img src={mailCheckImage.src} alt="" class="w-32 sm:w-auto" />
      </div>
      <p class="mb-6 text-sm text-panther-700 sm:mb-6">
        Zur Bestätigung Ihres Kontos haben wir Ihnen eine E-Mail gesendet. Klicken Sie auf den Link in der E-Mail, um
        die Registrierung abzuschließen. Prüfen Sie auch Ihren Spamordner, falls Sie keine E-Mail erhalten haben.
      </p>
      <div class="flex flex-col items-center justify-between gap-y-2 sm:flex-row">
        <div class="grow text-xs font-medium text-panther-950 sm:text-sm">E-Mail nicht erhalten?</div>
        <div>
          <Button label="E-Mail erneut senden" type="button" mode="secondary" onClick={resendEmailVerification} />
        </div>
      </div>
    </>
  );
};

export default RegistrationInitSuccess;
