const DISALLOWED_EMAIL_DOMAINS = [
  'aol.com',
  'freenet.de',
  'gmail.com',
  'gmx.com',
  'gmx.de',
  'gmx.net',
  'googlemail.com',
  'hotmail.com',
  'icloud.com',
  'live.com',
  'mail.com',
  'mail.ru',
  'me.com',
  'msn.com',
  'outlook.com',
  'protonmail.com',
  't-online.de',
  'web.de',
  'yahoo.com',
  'yahoo.de',
  'yandex.com',
];

export const isBusinessEmail = (domain: string): boolean => {
  return DISALLOWED_EMAIL_DOMAINS.includes(domain);
};
