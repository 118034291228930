import {
  createForm,
  email,
  type FieldValues,
  type Maybe,
  type MaybePromise,
  required,
  submit,
  type SubmitHandler,
  validate,
} from '@modular-forms/solid';
import { createScriptLoader } from '@solid-primitives/script-loader';
import type { JSX } from 'solid-js';
import { onMount } from 'solid-js';

import Button from '@components/controls/button';
import TextInput from '@components/controls/text-input';

import { isBusinessEmail } from '@lib/email.ts';

const COMMON_REQUIRED_MESSAGE = 'Eingabe erforderlich.';
const EMAIL_FORMAT_MESSAGE = 'Geben Sie eine gültige E-Mail-Adresse ein.';
const BUSINESS_EMAIL_MESSAGE = 'Bitte verwenden Sie eine geschäftliche E-Mail-Adresse.';

export const validateBusinessEmail = (value: Maybe<string>): MaybePromise<string> => {
  if (value === undefined) return '';
  const domain = value.split('@')[1];
  return isBusinessEmail(domain) ? BUSINESS_EMAIL_MESSAGE : '';
};

export interface RegisterForm extends FieldValues {
  email: string;
  emailDoubleCheck: string;
}

const RegistrationInitForm = ({ onSubmit }: { onSubmit: SubmitHandler<RegisterForm> }): JSX.Element => {
  const [store, { Form, Field }] = createForm<RegisterForm>();

  onMount(() => {
    createScriptLoader({ src: "plausible('registration-signup')" });
  });

  const handleEnter = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (await validate(store)) {
        submit(store);
      }
    }
  };

  return (
    <>
      <h1 class="mb-2.5 font-redhat text-2xl font-bold sm:text-4xl">Konto erstellen</h1>
      <p class="mb-6">Starten Sie jetzt mit der kostenlosen Nutzung von Profilery.</p>
      <Form onSubmit={onSubmit} id="registration-form">
        <div class="mb-5 flex flex-col gap-3.5">
          <Field
            name="email"
            validate={[required(COMMON_REQUIRED_MESSAGE), email(EMAIL_FORMAT_MESSAGE), validateBusinessEmail]}
          >
            {(field, props) => (
              <TextInput
                {...props}
                type="email"
                label="E-Mail (geschäftlich)"
                value={field.value}
                error={field.error}
                required
                autoFocus
                onKeyPress={handleEnter}
              />
            )}
          </Field>
          <Field name="emailDoubleCheck">
            {(field, props) => (
              <TextInput
                {...props}
                class="!hidden"
                type="text"
                label="E-Mail verifizieren"
                value={field.value}
                error={field.error}
              />
            )}
          </Field>
          <Button label="Kostenlos starten" type="submit" expanded />
        </div>
        <div class="mt-4 flex h-fit flex-col items-center justify-between border-t border-panther-500 pt-4 sm:flex-row">
          <p class="text-sm text-panther-500">Bereits registriert?</p>
          <p>
            <a href="/login/" class="font-bold text-panther-950 underline">
              jetzt einloggen
            </a>
          </p>
        </div>
      </Form>
    </>
  );
};

export default RegistrationInitForm;
