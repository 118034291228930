import { type SubmitHandler } from '@modular-forms/solid';
import { createScriptLoader } from '@solid-primitives/script-loader';
import { createSignal, type JSX, Match, Switch } from 'solid-js';
import { onMount } from 'solid-js';

import RegistrationInitForm, { type RegisterForm } from '@components/kostenlos-testen/registration-init-form';
import RegistrationInitSuccess from '@components/kostenlos-testen/registration-init-success.tsx';
import Spinner from '@components/kostenlos-testen/spinner';

import { initiateOrder, resendDOI } from '@lib/order.ts';

type DialogState = 'FORM' | 'SUBMITTING' | 'ORDER_INITIATED' | 'EMAIL_RESENT' | 'ERROR';

const RegistrationInitDialog = (): JSX.Element => {
  const [getStatus, setStatus] = createSignal<DialogState>('FORM');
  const [getOrderId, setOrderId] = createSignal<string>();

  onMount(() => {
    // Reset form when the dialog is closed
    const dialog = document.getElementById('registration-modal');
    if (dialog) {
      const observer = new MutationObserver(() => setStatus('FORM'));
      observer.observe(dialog, { attributes: true, attributeFilter: ['open'] });
      return () => observer.disconnect();
    }
  });

  const sendOrder: SubmitHandler<RegisterForm> = async (values) => {
    setStatus('SUBMITTING');
    try {
      const order = await initiateOrder({ ...values });
      setOrderId(order.orderId);
      setStatus('ORDER_INITIATED');
    } catch (err) {
      setStatus('ERROR');
    }

    createScriptLoader({ src: "plausible('registration-signup-submit')" });
  };

  const resendEmailVerification = () => {
    const orderId = getOrderId();
    if (orderId) {
      resendDOI(orderId);
      setStatus('EMAIL_RESENT');
    }
  };

  return (
    <div class="max-h-full overflow-y-auto px-4 pb-8 pt-10 sm:px-8">
      <Switch>
        <Match when={getStatus() === 'FORM'}>
          <RegistrationInitForm onSubmit={sendOrder} />
        </Match>
        <Match when={getStatus() === 'ORDER_INITIATED'}>
          <RegistrationInitSuccess resendEmailVerification={resendEmailVerification} />
        </Match>
        <Match when={getStatus() === 'EMAIL_RESENT'}>
          <RegistrationInitSuccess resendEmailVerification={resendEmailVerification} resent />
        </Match>
        <Match when={getStatus() === 'SUBMITTING'}>
          <Spinner />
        </Match>
        <Match when={getStatus() === 'ERROR'}>
          <div>Es ist leider ein Fehler aufgetreten. Bitte wenden Sie sich an support@profilery.de.</div>
        </Match>
      </Switch>
    </div>
  );
};

export default RegistrationInitDialog;
